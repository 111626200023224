import {
  Link,
  Wrapper,
  SessionLogout,
  ConnectionView,
  Image,
  Icon,
  SessionRole,
  UploadProgress,
  Inserter,
  SessionPodcaster,
} from "nystem-components";
import React, { useState, useEffect } from "react";
import app from "nystem";

const PartMenu = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = () => {
      if (open === true) setOpen(false);
    };
    setTimeout(() => {
      window.addEventListener("click", close);
    }, 0);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open]);

  return (
    <Wrapper renderAs="header" className="header">
      <Wrapper renderAs="nav" className="">
        <Link to="/" className="home cursor-pointer" exact="true">
          <Wrapper
            onClick={() => {
              document.documentElement.scrollTop = 0;
            }}
          >
            <ConnectionView>
              <Image
                src="/image/up-logo--beta.svg"
                width="120"
                heght="48"
                alt="logo"
              />
            </ConnectionView>
            <ConnectionView offline="true">
              <Image
                src="/image/up-logo--beta.svg"
                width="120"
                heght="48"
                className="grayscale"
                alt="logo"
              />
            </ConnectionView>
          </Wrapper>
        </Link>
        <Wrapper className="relative w-0">
          {open ? (
            <Wrapper className="fixed right-0 top-2 mt-16  mr-6 w-40 text-base text-gray-200 shadow-xl">
              <SessionRole userrole="super config">
                <Link to="/admin" className="navtab navtab--3d">
                  <Icon icon="gear" className="w-4 h-6" />
                </Link>
              </SessionRole>
              <SessionPodcaster>
                <Link to="/admin" className="navtab navtab--3d">
                  <Icon icon="gear" className="w-4 h-6" />
                </Link>
              </SessionPodcaster>
              <SessionRole userrole="logged-out">
                <Inserter match="*" source="/menu/view/mainloggedout" />
              </SessionRole>
              <SessionRole userrole="logged-in">
                <Inserter match="*" source="/menu/view/mainloggedin" />
                <SessionLogout
                  type="button"
                  className="navtab navtab--3d"
                  to="/"
                >
                  {app().t("Logga ut")}
                </SessionLogout>
              </SessionRole>
            </Wrapper>
          ) : null}
        </Wrapper>
        <Wrapper onClick={() => setOpen(!open)}>
          <SessionRole userrole="logged-out">
            <Inserter match="*" source="/menu/menubutton/mainloggedout" />
          </SessionRole>
          <SessionRole userrole="logged-in">
            <Inserter match="*" source="/menu/menubutton/mainloggedin" />
          </SessionRole>
        </Wrapper>
        <Wrapper className="main-menu">
          <UploadProgress className="mr-10 animate-bounce bg-gray-100 p-2 rounded" />
          <SessionRole userrole="super config">
            <Link to="/admin" className="navtab navtab--3d">
              <Icon icon="gear" className="w-4 h-6" />
            </Link>
          </SessionRole>
          <SessionPodcaster>
            <Link to="/admin" className="navtab navtab--3d">
              <Icon icon="gear" className="w-4 h-6" />
            </Link>
          </SessionPodcaster>
          <SessionRole userrole="logged-out">
            <Inserter match="*" source="/menu/view/mainloggedout" />
          </SessionRole>
          <SessionRole userrole="logged-in">
            <Inserter match="*" source="/menu/view/mainloggedin" />
            <SessionLogout type="button" className="navtab navtab--3d" to="/">
              {app().t("Logga ut")}
            </SessionLogout>
          </SessionRole>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
export default PartMenu;
