import { useEffect, useState } from "react";
import { TextView } from "nystem-components";
import app from "nystem";

const IntVat = ({ value, ...props }) => {
  const { vatfield, show, tofixed } = props.model;
  const [vat, setVat] = useState();

  useEffect(() => {
    let product = props.view.getValue(vatfield);
    product = product instanceof Array ? product : [product];
    const fetchVat = async () => {
      let vat = 0;
      for await (const oneProduct of product) {
        const { data } = await app().database.product.get({ id: oneProduct });
        vat += (data.vat / 100) * data.price;
      }
      setVat(vat);
    };
    fetchVat();
  }, [props.view, vatfield]);

  if (show !== "sum") value = vat;
  value = parseFloat(value).toFixed(tofixed).replace(".", ",");
  return <TextView {...props} value={value === 0 ? 0 : value} />;
};
export default IntVat;
