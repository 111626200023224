import { TextView } from "nystem-components";

const by3 = (number) => {
  return new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
  })
    .format(number)
    .replace("kr", "");
};

const IntMoney = ({ value, ...props }) => (
  <TextView
    {...props}
    value={value === 0 || value === undefined ? by3(0) : by3(value)}
  />
);
export default IntMoney;
